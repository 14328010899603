import { Box, Card, CardActionArea, CardMedia, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import react, { FunctionComponent, Fragment } from 'react';

interface Props {
    imgUrl: string,
    bgColor: string,
    language: string | null,
    SvgIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    iconColor: string,
    indonesianText: string[] | string,
    englishText: string[] | string
}


export const ImageIconCard: FunctionComponent<Props> = ({ imgUrl, bgColor, language, SvgIcon, iconColor, indonesianText, englishText }) => {

    const theme = useTheme();

    
    function getText() {
        if (language === 'id'){
            if (indonesianText instanceof Array) {
                return indonesianText.map((text) => {
                    return (<Typography
                        variant="h5"
                        sx={{

                            color: iconColor,
                            fontWeight: 'bold',
                        }}
                    >
                        {text}
                    </Typography>);
                });
            }
            else {
                return <Typography
                    variant="h5"
                    sx={{
                        color: iconColor,
                        fontWeight: 'bold',
                    }}
                >
                    {indonesianText}
                </Typography>
            }
        }
        else{
            if (englishText instanceof Array) {
                return englishText.map((text) => {
                    return (<Typography
                        variant="h5"
                        sx={{

                            color: iconColor,
                            fontWeight: 'bold',
                        }}
                    >
                        {text}
                    </Typography>);
                });
            }
            else {
                return <Typography
                    variant="h5"
                    sx={{
                        color: iconColor,
                        fontWeight: 'bold',
                    }}
                >
                    {englishText}
                </Typography>
            }

        }

    }

    return (
        <Card sx={{ 
            maxWidth: "100%",
            pointerEvents: "none"
         }}>
            <CardActionArea sx={{ position: "relative" }}>
                <CardMedia
                    component="img"
                    height="280"
                    image={`${imgUrl}`}
                    sx={{ position: "absolute", top: 0, left: 0 }}
                />
                <Box sx={{
                    position: "relative",
                    height: 280,
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: bgColor
                }}>
                    <SvgIcon
                        sx={{
                            fontSize: 72,
                            color: iconColor,
                            paddingBottom: 3
                        }} />

                    {getText()}
                </Box>
            </CardActionArea>
        </Card>
    );
};