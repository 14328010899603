import { Button, Grid, Typography, useTheme } from "@mui/material";
import { Fragment, FunctionComponent } from "react";

interface Props{
    language : string | null
}

export const HeaderText: FunctionComponent<Props> = ({language}) => {

    const theme = useTheme();

    const scrollToElementById = (id: string) => {
        const relevantElement = document.getElementById(id)!;
        relevantElement.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <Fragment>
            <Grid xs={10} container>
                <Typography variant="h4" sx={{ fontWeight: "600", display: { xs: 'flex', xl: 'none' } }}>
                    {language === 'id' ?
                        "Kami Menyediakan Pelayanan Mechanical Electric dan Plumbing Secara Efisien"
                        :
                        "We Provide Efficient Mechanical Electric And Plumbing Services"
                    }
                </Typography>
                <Typography variant="h2" sx={{ fontWeight: "600", display: { xs: 'none', xl: 'flex' } }}>
                    {language === 'id' ?
                        "Kami Menyediakan Pelayanan Mechanical Electric dan Plumbing Secara Efisien"
                        :

                        "We Provide Efficient Mechanical Electric And Plumbing Services"
                    }
                </Typography>
                <Typography variant="body1" sx={{ py: 2 }}>
                    {language === 'id' ?
                        "Kami menyediakan layanan komprehensif sepanjang waktu untuk pelanggan bisnis, komersial, perumahan multi-keluarga, dan industri"
                        :
                        "We provide comprehensive, around-the-clock services to business, commercial, multi-family residential and industrial customer"
                    }
                </Typography>
                <Grid container>
                    <Grid xs={5} md={4}>
                        <Button variant="contained" onClick={() => scrollToElementById('about')}>
                            {language === 'id' ?
                                "Tentang Kami"
                                :
                                "About Us"
                            }
                        </Button>
                    </Grid>
                    <Grid xs={5} md={4}>
                        <Button variant="text" onClick={() => scrollToElementById('contact')}>
                            {language === 'id' ?
                                "Hubungi Kami"
                                :
                                "Contact Us"
                            }
                        </Button>
                    </Grid>
                    <Grid xs={4}></Grid>
                </Grid>
                {/* <Grid container sx={{ pt: 2 }}>
                    <Grid xs={4}>
                        <Typography sx={{ typography: { xs: 'h4', sm: 'h3', md: 'h2' }, fontWeight: '400'} }>
                            500+
                        </Typography>
                        <Typography variant="body2">
                            {language === 'id' ?
                                "Kilen Puas"
                                :
                                "Satisfied Clients"
                            }
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography sx={{ typography: { xs: 'h4', sm: 'h3', md: 'h2' }, fontWeight: '400'} }>
                            900+
                        </Typography>
                        <Typography variant="body2">
                            {language === 'id' ?
                                "Kilen Puas"
                                :
                                "Satisfied Clients"
                            }
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography sx={{ typography: { xs: 'h4', sm: 'h3', md: 'h2' }, fontWeight: '400'} }>
                            60+
                        </Typography>
                        <Typography variant="body2">
                            {language === 'id' ?
                                "Kilen Puas"
                                :
                                "Satisfied Clients"
                            }
                        </Typography>
                    </Grid>
                </Grid> */}
            </Grid>
        </Fragment>
    );
}