import { Box, Button, Grid, Card, CardActionArea, CardMedia, Typography, Paper, List, ListItem, ListItemText, ListItemIcon, Divider, CardContent } from "@mui/material";
import React, { Fragment, FunctionComponent, useRef } from "react";
import Wave from 'react-wavify';

import StrokeBackground from "../assets/background-wave.svg";

import HomeBanner from "../assets/homepage/pipe-banner.png";
import DuctingImageOne from "../assets/homepage/projects/ducting.jpeg";
import DuctingImageTwo from "../assets/homepage/projects/ducting-2.jpeg";
import HvacImage from "../assets/homepage/projects/hvac-back.jpeg";
import PlumbingImage from "../assets/homepage/projects/plumbing.jpeg";
import CoolingTowerImage from "../assets/homepage/projects/cooling-tower.jpg";
import WasteWaterImage from "../assets/homepage/projects/waste-water.jpeg";
import CleanWaterImage from "../assets/homepage/projects/clean-water.jpg";
import HydrantImage from "../assets/homepage/projects/fire-hydrant.jpg";
import SpriklerImage from "../assets/homepage/projects/sprikler.jpeg";
import WaterPumpImage from "../assets/homepage/projects/water-pump.jpg";

import APJImage from "../assets/homepage/clients/APJ.png";
import EmkayImage from "../assets/homepage/clients/emkay.png";
import FalconImage from "../assets/homepage/clients/falcon.png";
import StAntoniusImage from "../assets/homepage/clients/stantonius.png";
import SwillHouseImage from "../assets/homepage/clients/swillhouse.png";
import ZooSCBDImage from "../assets/homepage/clients/zoo scbd.png";
import TigamasImage from "../assets/homepage/clients/tigamas.png";
import AdhiImage from "../assets/homepage/clients/adhi.png";

import CertImageOne from "../assets/homepage/certificates/43221.jpg";
import CertImageTwo from "../assets/homepage/certificates/43224.jpg";

import DaikinImage from "../assets/homepage/vendor/daikin.png";
import PanasonicImage from "../assets/homepage/vendor/panasonic.png";
import GreeImage from "../assets/homepage/vendor/gree.png";
import SamsungImage from "../assets/homepage/vendor/samsung.png";


import Aircon from "../components/lotties/Aircon";
import Plumber from "../components/lotties/Plumber"
import IndustrialPipe from "../components/lotties/IndustrialPipe"
import { useTheme } from '@mui/material/styles';
import { display } from "@mui/system";
import RollingBackground from "../components/lotties/RollingBackground";
import { AirTwoTone, ArrowUpwardTwoTone, FiberManualRecordOutlined, FireHydrantAltTwoTone, HeatPumpTwoTone, Hvac, PlumbingTwoTone, ShowerTwoTone, WaterDrop, WaterDropOutlined, WaterDropTwoTone } from "@mui/icons-material";
import { ReactComponent as RawLogo } from "../assets/logo.svg";
import AcUnitTwoToneIcon from '@mui/icons-material/AcUnitTwoTone';
import { ImageIconCard } from "../components/ImageIconCard";
import { HeaderText } from "../components/homepage/HeaderText";
import {ImageCard3d} from "../components/ImageCard3d";
import Marquee from "react-fast-marquee";

interface Props {
    language: string | null
}

//set language as the prop here using tsx functional component provided by
export const Home: FunctionComponent<Props> = ({ language }) => {

    const theme = useTheme();
    const HeaderLogo = () => <RawLogo height={50} width={100} />

    function hexToRgb(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        interface rgb {
            r: number,
            g: number,
            b: number
        };

        let output: rgb;
        if (result) {
            output = {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            }
        } else {
            output = {
                r: 0,
                g: 0,
                b: 0
            }
        }

        return output;
    }

    return (
        <Fragment>
            <Grid
                container
                sx={{
                    // pt: 8,
                    backgroundColor: `${theme.palette.grey[100]}`
                }}
            >

                <Grid xl={2} xs={1} md></Grid>
                <Box component={Grid} md={5} xl={4} display={{ xs: 'none', md: 'block' }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', height: "100%" }}>
                        <Grid container>
                            <HeaderText language={language} />
                        </Grid>
                    </Box>
                </Box>
                <Grid xs={12} md={6} sx={{ position: "relative" }}>
                    <RollingBackground width="100%" height="100%" />
                    <Box
                        component="img"
                        sx={(theme) => ({
                            objectFit: "cover",
                            width: "100%",
                            height: "auto",
                            position: "relative",
                            [theme.breakpoints.down("lg")]: {
                                height: "100%",
                            },
                            [theme.breakpoints.down("md")]: {
                                height: "450px",
                            },
                            [theme.breakpoints.down("sm")]: {
                                height: "700px",
                            },
                        })}
                        src={`${HomeBanner}`}
                    />

                    <Box display={{ xs: 'flex', md: 'none' }} sx={{ position: "absolute", top: 0, left: 0, alignItems: 'center', height: "100%", zIndex: 2, backgroundColor: "rgba(255,255,255, 0.8)" }}>
                        <Grid xs={1} />
                        <Grid xs={10} container>
                            <HeaderText language={language} />
                        </Grid>
                        <Grid xs={1} />
                    </Box>
                </Grid>
            </Grid>

            <Grid container sx={{ py: 4 }}>
                <Grid xs={1} xl={2}></Grid>
                <Grid xs={10} xl={8} container id="about">
                    <Box sx={{ width: "100%", display: 'flex', flexDirection: "column", alignItems: "center", p: 2, pb: 6 }}>
                        <Typography align="center" variant="subtitle2">{language === 'id' ? "Nilai Inti" : "Core Values"}</Typography>
                        <Typography align="center" variant="h4" sx={{ fontWeight: "600" }}>{language === 'id' ? "Berkenalan-lah Dengan Kami" : "Get To Know Us!"}</Typography>
                    </Box>
                    <Grid container>
                        <Grid xs={12} lg={4} item>
                            <Typography variant="h5" sx={{ fontWeight: '600' }} textAlign={"center"}>{language === 'id' ? "Tentang Kami" : "About Us"}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "center", p: 1, pt: 9, pb: 8 }}>
                                <HeaderLogo />
                                <Typography
                                    variant="caption"
                                    noWrap
                                    component="a"
                                    href="/"
                                    sx={{
                                        pt: 1,
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    PT. ALSEN UTAMA MANDIRI
                                </Typography>

                            </Box>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemText>
                                        {language === 'id' ?
                                            "PT Alsen Utama Mandiri bergerak di bidang MEP (HVAC, Mechanical Electrical, dan Plumbing Engineering). Berdiri tahun 2023 dengan keunggulan staff dan tenaga kerja yang persistent dalam bidang masing - masing."
                                            :
                                            "PT Alsen Utama Mandiri is engaged in MEP (HVAC, Mechanical Electrical, and Plumbing Engineering). Established in 2023 with superior staffs and a persistent workforces in their respective fields."
                                        }
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>

                        <Divider orientation="vertical" variant="middle" sx={(theme) => ({
                            display: "block",
                            [theme.breakpoints.down("lg")]: {
                                display: "none",
                            },
                        })} />

                        <Grid xs={12} lg={4} item>
                            <Typography variant="h5" sx={{ fontWeight: '600' }} textAlign={"center"}>{language==='id'?'Visi':'Vision'}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "center", p: 1 }}>
                                <Plumber width={200} height={200} segmentStart={170} segmentEnd={230} />
                            </Box>
                            <List dense={true}>
                                <ListItem>
                                    {language === 'id' ?
                                        "Menjadi perusahaan yang bergerak di bidang HVAC, mechanical electrical, dan plumbing engineering yang berintegritas dan berkelanjutan dalam mencapai tujuan secara konsisten."
                                        :
                                        "Being a HVAC, mechanical electrical, and plumbing engineering company with integrity and sustainability in reaching goals with consistency."
                                    }
                                </ListItem>
                            </List>
                        </Grid>


                        <Divider orientation="vertical" variant="middle" sx={(theme) => ({
                            display: "block",
                            [theme.breakpoints.down("lg")]: {
                                display: "none",
                            },
                        })} />

                        <Grid xs={11.9} lg={3.9} item>
                            <Typography variant="h5" sx={{ fontWeight: '600' }} textAlign={"center"}>{language==='id'?'Misi':'Mission'}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "center", p: 1 }}>
                                <Plumber width={200} height={200} segmentStart={260} segmentEnd={360} />
                            </Box>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecordOutlined />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {language === 'id' ?
                                            "Menyediakan layanan yang profesional kepada klien"
                                            :
                                            "Providing professional services to clients"
                                        }
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecordOutlined />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {language === 'id' ?
                                            "Menjalin kerjasama yang saling menguntungkan antara perusahaan, mitra kerja, karyawan, dan klien"
                                            :
                                            "Building mutually benefit cooperation between companies, partners, employees, and clients"
                                        }
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FiberManualRecordOutlined />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {language === 'id' ?
                                            "Membina sumber daya manusia yang optimal dengan dilandasi kedisiplinan dan kerja keras"
                                            :
                                            "Fostering optimal human resources based on discipline and hard work"
                                        }
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={1} xl={2}></Grid>
            </Grid>

            <Grid container sx={{
                backgroundColor: "#f6f5f8",
                pb: 8,
                pt: 4
            }}>
                <Grid xs={1} xl={2}></Grid>
                <Grid xs={10} xl={8} container>
                    <Box sx={{ width: "100%", display: 'flex', flexDirection: "column", alignItems: "center", p: 2, pb: 6 }}>
                        <Typography align="center" variant="subtitle2">{language === 'id' ? "Melayani" : "Serving"}</Typography>
                        <Typography align="center" variant="h4" sx={{ fontWeight: '600' }}>{language === 'id' ? "Instalasi HVAC" : "HVAC Installation"}</Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={3}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <ImageIconCard
                                imgUrl={HvacImage}
                                SvgIcon={AcUnitTwoToneIcon}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={"Chiller Installation"}
                                indonesianText={"Instalasi Chiller"}
                                iconColor="white"
                                language={language}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <ImageIconCard
                                imgUrl={CoolingTowerImage}
                                SvgIcon={HeatPumpTwoTone}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={["Cooling Tower", "Installation"]}
                                indonesianText={["Instalasi", "Cooling Tower"]}
                                iconColor="white"
                                language={language}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <ImageIconCard
                                imgUrl={DuctingImageOne}
                                SvgIcon={AirTwoTone}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={"Ducting Installation"}
                                indonesianText={"Instalasi Ducting"}
                                iconColor="white"
                                language={language}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <ImageIconCard
                                imgUrl={PlumbingImage}
                                SvgIcon={PlumbingTwoTone}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={"Piping Installation"}
                                indonesianText={"Instalasi Piping"}
                                iconColor="white"
                                language={language}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={1} xl={2}></Grid>
            </Grid>


            <Grid container sx={{
                backgroundColor: "#f6f5f8",
                pb: 8,
            }}>
                <Grid xs={1} xl={2}></Grid>
                <Grid xs={10} xl={8} container>
                    <Box sx={{ width: "100%", display: 'flex', flexDirection: "column", alignItems: "center", p: 2, pb: 6 }}>
                        <Typography align="center" variant="h4" sx={{ fontWeight: '600' }}>{language === 'id' ? "Sistem Plumbing & Fire Fighting" : "Plumbing & Fire Fighting System"}</Typography>
                    </Box>
                    <Grid container spacing={4}>
                        <Box component={Grid} display={{ xs: 'none', md: 'block' }} item xs={1.5} />

                        <Grid item xs={12} sm={6} md={3}>
                            <ImageIconCard
                                imgUrl={CleanWaterImage}
                                SvgIcon={WaterDropOutlined}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={["Clean Water", "Installation"]}
                                indonesianText={["Instalasi", "Clean Water"]}
                                iconColor="white"
                                language={language}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <ImageIconCard
                                imgUrl={WasteWaterImage}
                                SvgIcon={WaterDrop}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={["Waste Water", "Installation"]}
                                indonesianText={["Instalasi", "Waste Water"]}
                                iconColor="white"
                                language={language}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <ImageIconCard
                                imgUrl={HydrantImage}
                                SvgIcon={FireHydrantAltTwoTone}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={"Hydrant Installation"}
                                indonesianText={"Instalasi Hydrant"}
                                iconColor="white"
                                language={language}
                            />
                        </Grid>

                        <Box component={Grid} display={{ xs: 'block', md: 'none' }} item xs={12} sm={6} md={3}>
                            <ImageIconCard
                                imgUrl={SpriklerImage}
                                SvgIcon={ShowerTwoTone}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={"Sprinkler Installation"}
                                indonesianText={"Instalasi Sprinkler"}
                                iconColor="white"
                                language={language}
                            />
                        </Box>

                        <Box component={Grid} display={{ xs: 'none', md: 'block' }} item xs={1.5} />

                    </Grid>
                    <Grid container spacing={4} pt={4}>

                        <Box component={Grid} display={{ xs: 'none', sm: 'block' }} item sm={3} md={3} />

                        <Box component={Grid} display={{ xs: 'none', md: 'block' }} item xs={12} sm={6} md={3}>
                            <ImageIconCard
                                imgUrl={SpriklerImage}
                                SvgIcon={ShowerTwoTone}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={"Sprinkler Installation"}
                                indonesianText={"Instalasi Sprinkler"}
                                iconColor="white"
                                language={language}
                            />
                        </Box>

                        <Grid item xs={12} sm={6} md={3}>
                            <ImageIconCard
                                imgUrl={WaterPumpImage}
                                SvgIcon={ArrowUpwardTwoTone}
                                bgColor="rgba(188,32,36,0.4)"
                                englishText={["Pump Installation", "with", "power & control"]}
                                indonesianText={["Instalasi Pump", "dengan", "power & control"]}
                                iconColor="white"
                                language={language}
                            />
                        </Grid>

                        <Box component={Grid} display={{ xs: 'none', sm: 'block' }} item sm={3} md={3} />

                    </Grid>
                </Grid>
                <Grid xs={1} xl={2}></Grid>
            </Grid>

            <Grid container sx={{
                backgroundColor: theme.palette.primary.main,
                pb: 8,
                pt: 4
            }}>
                <Box sx={{ width: "100%", display: 'flex', flexDirection: "column", alignItems: "center", p: 2, pb: 6 }}>
                    <Typography align="center" variant="h4" sx={{ fontWeight: '600', color: "white" }}>{language === 'id' ? "Klien Kami" : "Our Satisfied Clients"}</Typography>
                </Box>
                <Marquee
                    speed={60}
                    gradient={true}
                    gradientColor={[hexToRgb(theme.palette.primary.main).r, hexToRgb(theme.palette.primary.main).g, hexToRgb(theme.palette.primary.main).b]}
                    gradientWidth={"30%"}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', height: "100%" }}>
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${APJImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${EmkayImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${FalconImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${StAntoniusImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${SwillHouseImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${ZooSCBDImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${TigamasImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${AdhiImage}`}

                        />
                    </Box>


                </Marquee>
            </Grid>

            <Grid container sx={{
                backgroundColor: "#f6f5f8",
                pb: 8,
                pt: 4
            }}>
                <Box sx={{ width: "100%", display: 'flex', flexDirection: "column", alignItems: "center", p: 2, pb: 6 }}>
                    <Typography align="center" variant="h4" sx={{ fontWeight: '600' }}>{language === 'id' ? "Didukung Oleh" : "Supported By"}</Typography>
                </Box>
                <Marquee
                    speed={60}
                    gradient={true}
                    gradientColor={[246, 245, 248]}
                    gradientWidth={"30%"}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', height: "100%" }}>
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${DaikinImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${PanasonicImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${GreeImage}`}

                        />
                        <Box
                            component="img"
                            maxHeight="200px"
                            width="200px"
                            paddingX={7}
                            src={`${SamsungImage}`}

                        />

                    </Box>


                </Marquee>
            </Grid>

            <Grid container sx={{
                backgroundColor: "#f6f5f8",
                pb: 8,
                pt: 4
            }}>
                <Box sx={{ width: "100%", display: 'flex', flexDirection: "column", alignItems: "center", p: 2, pb: 6 }}>
                    <Typography align="center" variant="h4" sx={{ fontWeight: '600' }}>{language === 'id' ? "Disertifikasi Oleh" : "Certified by"}</Typography>
                </Box>
                <Grid xs={1} xl={2}></Grid>
                <Grid xs={10} xl={8} container>
                    <Grid xs={0} md={3}/>
                    <Grid xs={12} md={6} container>
                        <Grid xs={5.5}>
                            <ImageCard3d
                                width={"100%"}
                                imgUrl={CertImageOne}
                            />
                        </Grid>
                        <Grid xs={1}></Grid>
                        <Grid xs={5.5}>
                            <ImageCard3d
                                width={"100%"}
                                imgUrl={CertImageTwo}
                            />
                        </Grid>
                    </Grid>
                    <Grid xs={0} md={3}/>
                </Grid>
                <Grid xs={1} xl={2}></Grid>
            </Grid>

        </Fragment>
    );
}