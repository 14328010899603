import React, { Component } from "react";
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from "@mui/material";
import { Email, Phone, LocationOn } from '@mui/icons-material';
import { ReactComponent as RawLogo } from "../assets/logo.svg";

export default function Footer() {
    const theme = useTheme();
    const HeaderLogo = () => <RawLogo height={50} width={100} />

    return (
        <Grid container spacing={3} sx={{ pt: 4 }}>
            <Grid item xs={1} md={3}>
            </Grid>
            <Grid item xs={10} md={6} container rowSpacing={3} id="contact">
                <Grid container item justifyContent={"center"}>
                    <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "center", p: 1 }}>
                        <HeaderLogo />
                        <Typography
                            variant="caption"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                pt: 1,
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            PT. ALSEN UTAMA MANDIRI
                        </Typography>
                    </Box>
                </Grid>
                <Grid container item spacing={3}>

                    <Grid item xs={12} md={7}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.6794158337434!2d106.68430617632666!3d-6.305783461714603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e5b1023af321%3A0x936ba7816ba5db52!2sPT.%20Alsen%20Utama%20Mandiri!5e0!3m2!1sen!2sid!4v1688740330792!5m2!1sen!2sid"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen={false}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemIcon>
                                    <LocationOn />
                                </ListItemIcon>
                                <ListItemText>
                                    Ruko Golden Madrid X blok B/9 BSD City
                                </ListItemText>
                            </ListItem>

                            <ListItem href={`mailto:alsenutamamandiri@gmail.com`} component="a" sx={{ color: theme.palette.grey[900] }}>
                                <ListItemIcon>
                                    <Email />
                                </ListItemIcon>
                                <ListItemText>
                                    alsenutamamandiri@gmail.com
                                </ListItemText>
                            </ListItem>

                            <ListItem href={`tel:02153198763`} component="a" sx={{ color: theme.palette.grey[900] }}>
                                <ListItemIcon>
                                    <Phone />
                                </ListItemIcon>
                                <ListItemText>
                                    (021) 53198763
                                </ListItemText>
                            </ListItem>

                        </List>
                    </Grid>

                </Grid>
                <Grid container item justifyContent={"center"}>
                    <Grid container justifyContent={"center"}>
                        <Typography textAlign={"center"} variant={"caption"}>
                            © 2023 PT. Alsen Utama Mandiri
                        </Typography>
                    </Grid>
                    <Grid container justifyContent={"center"} sx={{ pb: 2 }}>
                        <Typography textAlign={"center"} variant={"caption"}>
                            Design & development by Vincent Lioe
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} md={3}>
            </Grid>
        </Grid>

    );

}