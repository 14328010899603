import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import {Home} from "./pages/Home";
import Portofolio from "./pages/Portofolio";
import { NavBar } from "./components/NavBar";
import Footer from "./components/Footer";
import { red } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Language } from "@mui/icons-material";
import ScrollToTopButton from "./components/ScrollToTopButton";

interface State {
  language: string|null;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#bc2024",
    },
  },
});



class App extends Component<{}, State> {

  state = {
    language: 'id'
  }

  private toggleLanguage = (language: string|null) => {
    this.setState({
      language: language
    });

    return null;
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <NavBar language={this.state.language} toggleLanguage={this.toggleLanguage}/>

          <Routes>
            <Route path="/" element={<Home language={this.state.language} />} />
            {/* <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Portofolio />} />
            <Route path="/contact" element={<Contact />} /> */}
          </Routes>

          <Footer />
          <ScrollToTopButton
            showBelow={250}
          />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;