
import React, { useState, useEffect, FunctionComponent } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';

interface Props {
    showBelow: number
}

const ScrollToTopButton: FunctionComponent<Props> = ({
    showBelow,
}) => {

    const theme = useTheme();

    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    const handleClick = () => {
        window[`scrollTo`]({ top: 0, behavior: `smooth` })
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    });

    function hexToRgb(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        interface rgb {
            r: number,
            g: number,
            b: number
        };

        let output: rgb;
        if (result) {
            output = {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            }
        } else {
            output = {
                r: 0,
                g: 0,
                b: 0
            }
        }

        return output;
    };

    return (
        <div>
            {show &&
                <IconButton
                    onClick={handleClick}
                    sx={{
                        zIndex: 2,
                        position: 'fixed',
                        bottom: '5vh',
                        backgroundColor: '#DCDCDC',
                        color: 'white',
                        "&:hover, &.Mui-focusVisible": {
                            transition: '0.3s',
                            color: 'white',
                            backgroundColor: `rgb(${hexToRgb(theme.palette.primary.main).r}, ${hexToRgb(theme.palette.primary.main).g}, ${hexToRgb(theme.palette.primary.main).b},0.8)`,
                        },
                        [theme.breakpoints.up('xs')]: {
                            right: '5%',
                            backgroundColor: `rgb(${hexToRgb(theme.palette.primary.main).r}, ${hexToRgb(theme.palette.primary.main).g}, ${hexToRgb(theme.palette.primary.main).b},0.5)`,
                        },
                        [theme.breakpoints.up('lg')]: {
                            right: '6.5%',
                        },
                    }}
                    aria-label="to top"
                    component="span">
                    <ExpandLessIcon />
                </IconButton>
            }
        </div>
    )
}
export default ScrollToTopButton;