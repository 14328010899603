import React, { useEffect, useRef, FunctionComponent } from "react";
import lottie from "lottie-web";

interface Props {
    segmentStart: number,
    segmentEnd: number,
    width: number,
    height: number,
}

const Plumber: FunctionComponent<Props> = ({
    segmentStart,
    segmentEnd,
    width,
    height,
}) => {
    const container = useRef(null);

    useEffect(() => {
        const instance = lottie.loadAnimation(

            {
            container: container.current!, //where the element will exist in the DOM
            renderer: "svg",
            loop: true, //set to true if you want it to continuously play
            autoplay: true,
            animationData: require("../../assets/lotties/14525-plumbers.json"), //set to where your animation is located
            }
        );
        instance.playSegments([segmentStart, segmentEnd],true);
        return () => instance.destroy();
    }, [segmentStart, segmentEnd]);

    return (
        <div ref={container} style={{ width: width, height: height }} />
    );
};

export default Plumber;