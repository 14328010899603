import React, { useEffect, useRef, FunctionComponent } from "react";
import lottie from "lottie-web";

interface Props {
    width: any,
    height: any,
}

const RollingBackground: FunctionComponent<Props> = ({
    width,
    height,
}) => {
    const container = useRef(null);

    useEffect(() => {
        const instance = lottie.loadAnimation(

            {
            container: container.current!, //where the element will exist in the DOM
            renderer: "svg",
            loop: true, //set to true if you want it to continuously play
            autoplay: true,
            animationData: require("../../assets/lotties/2853-circle-red-button.json"), //set to where your animation is located
            }
        );
        // instance.playSegments([segmentStart, segmentEnd],true);
        return () => instance.destroy();
    }, []);

    return (
        <div ref={container} style={{ width: width, height: height, position: "absolute", top: 0, left: 0 }} />
    );
};

export default RollingBackground;