import { Box, Card, CardActionArea, CardMedia, Dialog, Modal, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';
import react, { FunctionComponent, Fragment } from 'react';
import Tilt from 'react-parallax-tilt';

interface Props {
    imgUrl: string,
    width: number | string,
}


export const ImageCard3d: FunctionComponent<Props> = ({ imgUrl, width }) => {

    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Fragment>
            <Tilt
                glareEnable={true}
            >
                <Box
                    onClick={handleOpen}
                    component="img"
                    sx={{
                        width: width,
                        boxShadow: 3,
                        cursor: 'pointer'
                    }}
                    src={imgUrl}
                />
            </Tilt>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullWidth
                maxWidth="lg"
            >
                <Box
                    component="img"
                    sx={{
                        width: "100%",
                        boxShadow: 3,
                        // scale: "1.25"
                    }}
                    src={imgUrl}
                />
            </Dialog>
        </Fragment>
    );
};