import * as React from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SvgIcon } from '@mui/material';

interface Props {
    language: string;
    toggleLanguage: (language: string|null) => void;
}

export const LanguagePicker: React.FunctionComponent<Props> = ({language, toggleLanguage}) => {

    const [alignment, setAlignment] = React.useState<string | null>(language);

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newLang: string | null,
    ) => {
        setAlignment(newLang);
        toggleLanguage(newLang);
    };

    return (
        <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
        >
            <ToggleButton value="id">
                <SvgIcon viewBox="0 0 512 512">
                    <path style={{ fill: "#C8414B" }} d="M400,0H112C50.144,0,0,50.144,0,112v144h512V112C512,50.144,461.856,0,400,0z" />
                    <path style={{ fill: "#F5F5F5" }} d="M0,400c0,61.856,50.144,112,112,112h288c61.856,0,112-50.144,112-112V256H0V400z" />
                </SvgIcon>
            </ToggleButton>
            <ToggleButton value="en">
                <SvgIcon viewBox="0 0 512 512">
                    <path d="M400 512H112C50.144 512 0 461.856 0 400V112C0 50.144 50.144 0 112 0h288c61.856 0 112 50.144 112 112v288c0 61.856-50.144 112-112 112z" style={{fill:"#41479b"}} />
                    <path d="M512 399.989v-24.587l-79.419-52.033H512v-26.948H296.421V512h26.947V348.46L494.3 460.45a111.414 111.414 0 0 0 13.75-30.896M7.531 440.443a111.728 111.728 0 0 0 10.168 20.007l170.932-111.99V512h26.947V296.421H0v26.948h79.419L0 375.402V400c0 4.306.252 8.553.725 12.733M0 115.807v20.791l79.419 52.034H0v26.947h215.579V0h-26.947v163.54L17.699 51.55A111.378 111.378 0 0 0 3.114 85.7M504.321 71.169a111.622 111.622 0 0 0-10.021-19.62L323.368 163.54V0h-26.947v215.579H512v-26.947h-79.419L512 136.598V112c0-4.46-.269-8.858-.776-13.182" style={{fill:"#f5f5f5"}} />
                    <path d="M296.421 0h-80.842v215.579H0v80.842h215.579V512h80.842V296.421H512v-80.842H296.421z" style={{fill:"#ff4b55"}} />
                    <path d="M138.395 323.369.725 412.733a111.28 111.28 0 0 0 6.807 27.71l180.359-117.075h-49.496v.001zM344.465 323.369l163.586 106.186A112.142 112.142 0 0 0 512 400v-.011l-118.039-76.621-49.496.001zM161.684 188.632 3.114 85.7A112.234 112.234 0 0 0 0 112v3.807l112.188 72.825h49.496zM372.863 188.632l138.36-89.814a111.284 111.284 0 0 0-6.902-27.648L323.368 188.632h49.495z" style={{fill:"#ff4b55"}} />

                </SvgIcon>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}